import EditIcon from '@mui/icons-material/Edit'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import GroupRemoveIcon from '@mui/icons-material/GroupRemove'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import StripePaymentComponent from 'components/stripe-payments'
import CircularProgressWithLabel from 'components/table/CircularProgressLabel'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import { useEffect, useRef, useState } from 'react'
import { MAX_ALLOWED_REGISTRATIONS, MIN_REQUIRED_REGISTRATIONS } from 'utils/constants'
import { updateSchool } from 'utils/schools'
import { MessageProps, PaymentProps } from 'utils/types'

export const BillingDisabledComponent = (props: any) => {
  const { onSubmit, body } = props
  useEffect(() => {
    onSubmit()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Alert severity='warning' variant='standard'>
      {body}
    </Alert>
  )
}

export const BillingMaxLimitComponent = (props: { onSubmit: any; maxRegistrations?: number }) => {
  const { maxRegistrations, onSubmit } = props
  const [value, setValue] = useState<number>(maxRegistrations ?? MAX_ALLOWED_REGISTRATIONS)

  return (
    <Box m={2} p={2}>
      <TextComponent align='left'>{`Set the maximum number of families allowed to register. Recurring charges at the end of each month are based on the actual number of registered families that month, not this maximum limit.`}</TextComponent>
      <Box display='flex' flexDirection={'row'} paddingTop={4} paddingBottom={2}>
        <GroupRemoveIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
        <Slider
          value={value}
          onChange={(event: Event, newValue: number | number[]) => {
            setValue(newValue as number)
            onSubmit(newValue as number)
          }}
          valueLabelDisplay='on'
          step={10}
          marks={[
            { value: MIN_REQUIRED_REGISTRATIONS, label: `${MIN_REQUIRED_REGISTRATIONS} User` },
            { value: MAX_ALLOWED_REGISTRATIONS, label: `Up to ${MAX_ALLOWED_REGISTRATIONS}` },
          ]}
          min={MIN_REQUIRED_REGISTRATIONS}
          max={MAX_ALLOWED_REGISTRATIONS}
          sx={{ mx: 2 }}
        />
        <GroupAddIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
      </Box>
    </Box>
  )
}

export const BillingPaymentComponent = (props: { name: string; onSubmit: any; paymentMethod?: PaymentProps }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '')
  const { name, onSubmit, paymentMethod } = props
  const [isShowPaymentForm, setIsShowPaymentForm] = useState<boolean>(paymentMethod ? false : true)
  const [isLoading, setIsLoading] = useState<string>('')
  const [clientSecret, setClientSecret] = useState<string>()
  const [msg, setMsg] = useState<MessageProps>()
  const scrollRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isShowPaymentForm) {
      (async () => {
        setIsLoading('Loading stripe payment form')
        const { msg, data, error } = await updateSchool(name, true, undefined, undefined, undefined, undefined, {
          isGetClientSecret: true,
        })
        if (error) setMsg(msg)
        else {
          if (scrollRef?.current) scrollRef.current.scrollIntoView()
          setClientSecret(data?.clientSecret as string)
        }
        setIsLoading('')
      })()
    }
  }, [isShowPaymentForm]) // eslint-disable-line

  if (!stripePromise || (!clientSecret && !paymentMethod)) return <LinearProgress />

  return (
    <div ref={scrollRef}>
      {/* Loading indicator for backend API calls */}
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle>{isLoading}</DialogTitle>
          <CircularProgressWithLabel />
        </Dialog>
      )}

      {/***************** Stripe Payment Container *****************/}
      {isShowPaymentForm && clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, appearance: { variables: { fontFamily: 'Open-sans' } } }}
        >
          <StripePaymentComponent
            isExisting={paymentMethod?.paymentType === 'card'}
            onPayment={(paymentMethodId: string) => {
              onSubmit(paymentMethodId)
            }}
          />
        </Elements>
      )}

      {/***************** Payment Details *****************/}
      {!isShowPaymentForm && paymentMethod?.paymentType === 'card' && (
        <Box>
          <Grid container alignItems='center'>
            <Grid item>
              <Typography variant='h6'>Payment Method</Typography>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                size='small'
                color='secondary'
                onClick={() => setIsShowPaymentForm(true)}
                sx={{ borderRadius: '50px', minWidth: '20px', padding: '10px', margin: '0 0.5rem' }}
              >
                <EditIcon sx={{ fontSize: '12px' }} />
              </Button>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextComponent variant='subtitle1' fontWeight='bold'>
                  Billing Cycle
                </TextComponent>
                <TextComponent variant='body1'>
                  {new Date(paymentMethod.currentPeriodStart * 1000).toLocaleDateString()} -{' '}
                  {new Date(paymentMethod.currentPeriodEnd * 1000).toLocaleDateString()}
                </TextComponent>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextComponent variant='subtitle1' fontWeight='bold'>
                  Payment Method
                </TextComponent>
                <TextComponent variant='body1'>
                  **** **** **** {paymentMethod.cardLast4} ({paymentMethod.cardBrand})
                </TextComponent>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextComponent variant='subtitle1' fontWeight='bold'>
                  Expiry
                </TextComponent>
                <TextComponent variant='body1'>
                  {paymentMethod.cardExpMonth}/{paymentMethod.cardExpYear}
                </TextComponent>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      {/***************** Display messages *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </div>
  )
}
