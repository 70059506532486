import { LinearProgress } from '@mui/material'
import AvatarComponent from 'components/avatar'
import ButtonMenuComponent from 'components/button-menu'
import ButtonMobileComponent from 'components/button-mobile'
import {
  CreateGroupIcon,
  MoreIcon,
  PrivateGroupIcon,
  PrivateGroupLabelIcon,
  PublicGroupIcon,
  PublicGroupLabelIcon,
} from 'components/icons'
import InlineEditInputComponent from 'components/inline-edit-input'
import InlineEditTextAreaComponent from 'components/inline-edit-textarea'
import ToastComponent from 'components/toast'
import React, { useEffect, useRef, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { RequiredFields } from 'types/CommonTypes'
import { RoomType, RoomUsersOutputProps, UserRoomsOutputProps } from 'types/RoomTypes'
import { MAX_ROOM_ADMINS, MIN_ROOM_ADMINS, titleCaseSchoolNameForUI } from 'utils/constants'
import { updateRoom } from 'utils/rooms'
import { MessageProps } from 'utils/types'
import { isValidGroupDescription, isValidGroupName } from 'utils/validations'

// Mock Data Types
interface RoomInfoComponentProps {
  room: UserRoomsOutputProps // room to lookup info
  roomUsers: RoomUsersOutputProps[] // all room users
  userId: string // current user
  onUpdateRoomAdmins: (id: string, admins: string[]) => Promise<void>
  onRemoveRoomUsers: (id: string, users: string[]) => Promise<void>
}

interface CardComponentProps {
  avatar?: JSX.Element
  title: string
  subtitle?: string
  body?: string
  footer?: JSX.Element
}

const CardComponent = (props: CardComponentProps): JSX.Element => {
  const { avatar, title, subtitle, body, footer } = props ?? {}

  return (
    <div className={`flex flex-col items-start gap-3 my-2 self-stretch lg:p-2 lg:border lg:rounded-xl`}>
      <div className='flex justify-between items-center self-stretch'>
        <div className='flex items-center gap-2 flex-1'>
          {avatar ? avatar : <AvatarComponent name={/^[A-Za-z]/.test(title) ? title : '#'}></AvatarComponent>}
          <div className='flex flex-col'>
            <div className='flex items-center gap-1 max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis'>
              <span className='text-[#474747] text-sm font-medium'>{title}</span>
              {subtitle && (
                <>
                  <span className='w-[1px] bg-gray-300 h-[20px]' />
                  {/* <div className='text-[12px] bg-light-yellow py-1 px-2 rounded-xl'>{subtitle}</div> */}
                  <span className='text-[#797979] text-xs font-medium'>{subtitle}</span>
                </>
              )}
            </div>
            {body && <span className='inline-block max-w-[51px] text-xs rounded-xl px-2 py-1 bg-gray-100'>{body}</span>}
          </div>
        </div>
        {footer}
      </div>
    </div>
  )
}
interface MenuComponentProps {
  isYou: boolean
  isAdmin: boolean
  roomId: string
  roomUserId: string
  roomAdmins: string[]
  onUpdateRoomAdmins: (id: string, admins: string[]) => Promise<void>
  onRemoveRoomUsers: (id: string, admins: string[]) => Promise<void>
}

const MenuComponent = (props: MenuComponentProps): JSX.Element => {
  const { isYou, isAdmin, roomId, roomUserId, roomAdmins, onUpdateRoomAdmins, onRemoveRoomUsers } = props ?? {}
  const [isAllowAddAdmin, setIsAllowAddAdmin] = useState<boolean>()
  const [isAllowRemoveAdmin, setIsAllowRemoveAdmin] = useState<boolean>()
  const [isAllowRemoveUser, setIsAllowRemoveUser] = useState<boolean>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const panelRef = useRef<HTMLDivElement>(null)

  //hooks
  useEffect(() => {
    setIsAllowAddAdmin(!isAdmin && roomAdmins.length < MAX_ROOM_ADMINS)
    setIsAllowRemoveAdmin(isAdmin && roomAdmins.length > MIN_ROOM_ADMINS)
    setIsAllowRemoveUser(!isYou)
    setIsOpen(false)
  }, [isYou, isAdmin, roomAdmins])
  useEffect(() => {
    if (isOpen) {
      const handleOutsideInteraction = (event: MouseEvent | TouchEvent) => {
        const target = event.target as Node
        if (panelRef.current && !panelRef.current.contains(target)) {
          setIsOpen(false)
        }
      }
      document.addEventListener('mousedown', handleOutsideInteraction)
      document.addEventListener('touchstart', handleOutsideInteraction)
      return () => {
        document.removeEventListener('mousedown', handleOutsideInteraction)
        document.removeEventListener('touchstart', handleOutsideInteraction)
      }
    }
  }, [isOpen, panelRef])

  const isShowMenu: boolean = isAllowAddAdmin || isAllowRemoveAdmin || isAllowRemoveUser ? true : false

  return isShowMenu ? (
    <>
      <div ref={panelRef} className='relative'>
        {/***************** Show menu button *****************/}
        <button className='flex items-center' onClick={() => setIsOpen(!isOpen)}>
          <MoreIcon />
        </button>
        {/***************** Show menu items *****************/}
        {isOpen && (
          <div className='dropdown absolute top-full right-0 mt-2 min-w-[200px] flex flex-col items-start  pb-2 overflow-y-auto bg-white rounded-lg shadow-lg border border-gray-300 z-10'>
            {isAllowAddAdmin && (
              <ButtonMenuComponent
                name={'Make group admin'}
                onClick={() => onUpdateRoomAdmins(roomId, [...roomAdmins, roomUserId])}
              />
            )}
            {isAllowRemoveAdmin && (
              <>
                <hr className='w-full border-t border-gray-300' />
                <ButtonMenuComponent
                  name={'Remove group admin'}
                  onClick={() =>
                    onUpdateRoomAdmins(
                      roomId,
                      roomAdmins.filter((v) => v !== roomUserId),
                    )
                  }
                />
              </>
            )}
            {isAllowRemoveUser && (
              <>
                <hr className='w-full border-t border-gray-300' />
                <ButtonMenuComponent
                  name={'Remove from group'}
                  onClick={() => onRemoveRoomUsers(roomId, [roomUserId])}
                />
              </>
            )}
          </div>
        )}
      </div>
    </>
  ) : (
    <></>
  )
}

const RoomInfoComponent: React.FC<RoomInfoComponentProps> = ({
  room,
  roomUsers,
  userId,
  onUpdateRoomAdmins,
  onRemoveRoomUsers,
}) => {
  const navigate: NavigateFunction = useNavigate()
  const { roomType, roomId, roomAdmins, roomMemberCount, roomCommunityId, roomCreatedBy, roomCreatedAt } =
    room as RequiredFields<UserRoomsOutputProps, 'roomAdmins' | 'roomCommunityId' | 'roomCreatedBy'>
  const isUserRoomAdmin: boolean = roomAdmins?.includes(userId) ? true : false
  const createdByName: string = roomUsers.find((v) => v.userId === roomCreatedBy)?.userName || roomCreatedBy
  const createdAtLocaleDate: string = new Date(roomCreatedAt).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })
  const createdAtLocaleTime: string = new Date(roomCreatedAt).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  // State for selected school and room type filter
  const [filteredUsers, setFilteredUsers] = useState<CardComponentProps[]>([])
  const [roomName, setRoomName] = useState<string>(room.roomName)
  const [roomDescription, setRoomDescription] = useState<string>(room.roomDescription ?? '')
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // hooks
  useEffect(() => {
    const cards: CardComponentProps[] = []

    // Sort roomUsers based on the required order: isYou first, then admins alphabetically, and finally other members alphabetically.
    const sortedRoomUsers = roomUsers.slice().sort((a, b) => {
      const aIsYou = userId === a.userId
      const bIsYou = userId === b.userId

      if (aIsYou) return -1
      if (bIsYou) return 1

      const aIsAdmin = roomAdmins.includes(a.userId)
      const bIsAdmin = roomAdmins.includes(b.userId)

      if (aIsAdmin && bIsAdmin) return a.userName.localeCompare(b.userName)
      if (aIsAdmin) return -1
      if (bIsAdmin) return 1

      return a.userName.localeCompare(b.userName)
    })

    sortedRoomUsers.forEach((v: RoomUsersOutputProps) => {
      const { userId: uid, userName } = v
      const isYou: boolean = userId === uid
      const isAdmin: boolean = roomAdmins.includes(uid)
      cards.push({
        avatar: <AvatarComponent name={userName} />,
        title: `${userName} ${isYou ? '(You)' : ''}`,
        body: isAdmin ? 'Admin' : '',
        footer: isUserRoomAdmin ? (
          <MenuComponent
            isYou={isYou}
            isAdmin={isAdmin}
            roomId={roomId}
            roomUserId={uid}
            roomAdmins={roomAdmins}
            onUpdateRoomAdmins={onUpdateRoomAdmins}
            onRemoveRoomUsers={onRemoveRoomUsers}
          />
        ) : (
          <></>
        ),
      })
    })
    setFilteredUsers(cards)
  }, [roomId, userId, roomUsers, roomAdmins, isUserRoomAdmin, onUpdateRoomAdmins, onRemoveRoomUsers])

  // handlers
  const handleUpdateRoomName = async (rn: string) => {
    setIsLoading('Updating room name...')
    const { msg, error } = await updateRoom(roomId, undefined, rn)
    if (error) setMsg(msg)
    setIsLoading('')
  }
  const handleUpdateRoomDescription = async (rd: string) => {
    setIsLoading('Updating room description...')
    // Create group rooms
    const { msg, error } = await updateRoom(roomId, undefined, undefined, rd)
    if (error) setMsg(msg)
    setIsLoading('')
  }

  // Filtered Rooms
  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/* **************** Body **************** */}
      <div className='flex flex-col flex-1 self-stretch px-5 gap-2 mb-[120px]'>
        {/* **************** Room Name **************** */}
        <div className={`flex flex-col items-start gap-3 my-4 self-stretch lg:p-2 lg:border lg:rounded-xl`}>
          <div className='flex justify-between items-center self-stretch'>
            {/****** Left Element ******/}
            <div className='flex items-center gap-3 flex-1'>
              {roomType === RoomType.GROUP ? <PrivateGroupIcon /> : <PublicGroupIcon />}
              <div className='flex flex-col'>
                <div className='flex items-center'>
                  <InlineEditInputComponent
                    defaultValue={roomName}
                    isEditable={isUserRoomAdmin}
                    validate={(v: string) => isValidGroupName(v, true)}
                    onSave={(v: string) => {
                      setRoomName(v)
                      handleUpdateRoomName(v)
                    }}
                  />
                </div>
                <span className='text-[#797979] text-xs font-regular max-w-[250px]'>
                  {`Created by ${createdByName}, on `}
                </span>
                <span className='text-[#797979] text-xs font-regular max-w-[250px]'>
                  {`${createdAtLocaleDate} at ${createdAtLocaleTime}`}
                </span>
              </div>
            </div>
            {/****** Right Element ******/}
            <div className='flex flex-col items-end gap-2'>
              {roomType === RoomType.GROUP ? <PrivateGroupLabelIcon /> : <PublicGroupLabelIcon />}
              <span className='text-xs rounded-xl px-1 py-0 bg-light-yellow border-custom-yellow border text-gray-800 truncate w-[100px]'>
                {titleCaseSchoolNameForUI('long named ' + roomCommunityId)}
              </span>
            </div>
          </div>
        </div>

        {/* **************** Room Description **************** */}
        <InlineEditTextAreaComponent
          defaultValue={roomDescription}
          placeholder={isUserRoomAdmin ? 'Add description' : 'No description'}
          isEditable={isUserRoomAdmin}
          validate={isValidGroupDescription}
          onSave={(v: string) => {
            setRoomDescription(v)
            handleUpdateRoomDescription(v)
          }}
          className='mb-4'
        />

        {/* **************** Member List **************** */}
        <span className='text-[#474747] text-xs font-regular'>{roomMemberCount} members</span>
        {filteredUsers.map((room: CardComponentProps, index: number) => (
          <CardComponent
            key={index}
            avatar={room.avatar}
            title={room.title}
            subtitle={room.subtitle}
            body={room.body}
            footer={room.footer}
          />
        ))}
      </div>
      {/* **************** Footer **************** */}
      {roomType === RoomType.GROUP && (
        <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-3'>
          <div className='border-b border-gray-200'></div>
          <div className='pt-5 pb-10 px-5'>
            <ButtonMobileComponent
              text='Add members'
              color='bg-custom-yellow'
              width='w-full'
              onClick={() => navigate(`/messaging?action=roomAdd&roomId=${roomId}`)}
              iconPosition='left'
              icon={<CreateGroupIcon />}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default RoomInfoComponent
