import AvatarComponent from 'components/avatar'
import ButtonMobileComponent from 'components/button-mobile'
import { SearchIcon } from 'components/icons'
import InputMobileComponent from 'components/input-mobile'
import React, { useEffect, useState } from 'react'
import { RoomType, UserRoomsOutputProps } from 'types/RoomTypes'
import { titleCaseSchoolNameForUI } from 'utils/constants'

// Mock Data Types
interface RoomJoinComponentProps {
  rooms: UserRoomsOutputProps[]
  communities: Record<string, { studentId: string; isNeedPMPool: boolean }>
  userId: string
  onAddRoomUsers: (id: string, users: string[]) => Promise<void>
}

interface CardComponentProps {
  avatar?: JSX.Element
  title: string
  subtitle?: string
  body?: string
  footer?: JSX.Element
}

const CardComponent = (props: CardComponentProps): JSX.Element => {
  const { avatar, title, subtitle, body, footer } = props ?? {}

  return (
    <div className={`flex flex-col items-start gap-3 my-4 self-stretch lg:p-2 lg:border lg:rounded-xl`}>
      <div className='flex justify-between items-center self-stretch'>
        <div className='flex items-center gap-2 flex-1'>
          {avatar}
          <div className='flex flex-col'>
            <div className='flex items-center gap-1 max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis'>
              <span className='text-[#474747] text-sm font-medium'>{title}</span>
              {subtitle && (
                <>
                  <span className='w-[1px] bg-gray-300 h-[20px]' />
                  {/* <div className='text-[12px] bg-light-yellow py-1 px-2 rounded-xl'>{subtitle}</div> */}
                  <span className='text-[#797979] text-xs font-medium'>{subtitle}</span>
                </>
              )}
            </div>
            <span className='text-[#797979] text-xs font-regular max-w-[250px] overflow-hidden whitespace-nowrap text-ellipsis'>
              {body}
            </span>
          </div>
        </div>
        {footer}
      </div>
    </div>
  )
}

const RoomJoinComponent: React.FC<RoomJoinComponentProps> = ({ rooms, communities, userId, onAddRoomUsers }) => {
  const filters: string[] = ['All', ...Object.keys(communities)]

  // State for selected school and room type filter
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [selectedFilter, setSelectedFilter] = useState<string>(filters[0])
  const [filteredRooms, setFilteredRooms] = useState<CardComponentProps[]>([])

  // hooks
  useEffect(() => {
    const cards: CardComponentProps[] = [],
      disabledCards: CardComponentProps[] = []
    rooms.forEach((v: UserRoomsOutputProps) => {
      const {
        roomType,
        roomId,
        roomName,
        roomDescription,
        roomMemberCount,
        roomCommunityId,
        roomCommunityActivity,
        isMember,
      } = v
      const isKeywordFound: boolean =
        !searchKeyword ||
        [roomName, roomDescription, roomCommunityId, roomCommunityActivity].some(
          (field) => field && field.toLowerCase().includes(searchKeyword.toLowerCase()),
        )
      const isRoomTypeFound: boolean = roomType === RoomType.PUBLIC || roomType === RoomType.ACTIVITY
      if (!isMember && isKeywordFound && isRoomTypeFound && roomCommunityId) {
        const isCommunityTypeFound: boolean = selectedFilter === 'All' || selectedFilter === roomCommunityId
        if (isCommunityTypeFound) {
          const { isNeedPMPool } = communities[roomCommunityId]
          const isMultipleCommunities: boolean = Object.keys(communities).length > 1 ? true : false
          const getJoinNowButton = (isDisabled?: boolean): JSX.Element => (
            <ButtonMobileComponent
              text='Join now'
              width='w-[90px]'
              padx='px-[12px]'
              height='h-[30px]'
              disabled={isDisabled}
              onClick={() => onAddRoomUsers(roomId, [userId])}
            />
          )
          if (roomType === RoomType.ACTIVITY && !isNeedPMPool) {
            // activity rooms need pm pool enabled
            disabledCards.push({
              avatar: <AvatarComponent name={roomName} />,
              title: roomName,
              subtitle:
                roomCommunityId && isMultipleCommunities ? titleCaseSchoolNameForUI(roomCommunityId) : undefined,
              body: `${roomMemberCount} members | Enable carpool to join`,
              footer: getJoinNowButton(true),
            })
          } else {
            cards.push({
              title: roomName,
              subtitle:
                roomCommunityId && isMultipleCommunities ? titleCaseSchoolNameForUI(roomCommunityId) : undefined,
              body: `${roomMemberCount} members`,
              footer: getJoinNowButton(),
            })
          }
        }
      }
    })
    setFilteredRooms([
      ...cards.sort((a, b) => a.title.localeCompare(b.title)),
      ...disabledCards.sort((a, b) => a.title.localeCompare(b.title)),
    ])
  }, [userId, communities, rooms, searchKeyword, selectedFilter, onAddRoomUsers])

  // Filtered Rooms
  return (
    <>
      {/* **************** Body **************** */}
      <div className='flex flex-col flex-1 self-stretch px-5 gap-2'>
        {/***************** Search Bar *****************/}
        <InputMobileComponent
          type='text'
          placeholder='Search public groups to join...'
          onChange={(v: string) => setSearchKeyword(v.trimEnd())}
          rightElement={<SearchIcon />}
        />
        {/***************** Room Type Selector *****************/}
        {filters.length > 2 && (
          <div className='flex flex-wrap my-2 gap-3'>
            {filters.map((v: string, i: number) => (
              <span
                key={i}
                onClick={() => setSelectedFilter(v)}
                className={`text-xs rounded-xl px-2 py-1 cursor-pointer ${
                  selectedFilter === v
                    ? 'bg-custom-yellow text-gray-800'
                    : 'bg-light-yellow border-custom-yellow border text-gray-800'
                }`}
              >
                {titleCaseSchoolNameForUI(v)}
              </span>
            ))}
          </div>
        )}
        {/* **************** Room List **************** */}
        {filteredRooms.map((room: CardComponentProps, index: number) => (
          <CardComponent
            key={index}
            avatar={room.avatar}
            title={room.title}
            subtitle={room.subtitle}
            body={room.body}
            footer={room.footer}
          />
        ))}
      </div>
    </>
  )
}

export default RoomJoinComponent
