import { LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import InputMobileComponent from 'components/input-mobile'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { setSessionItem } from 'utils/constants'
import { getSchool, verifySchoolCode } from 'utils/schools'
import { registerStudent } from 'utils/students'
import { MessageProps, UserProps } from 'utils/types'
import './index.css'

interface Props {
  user?: Pick<UserProps, 'relation'>
  onAdd: (ssi: string, cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name, User Relation and onAdd Callbck
  const { user, onAdd } = props ?? {}
  const [searchParams] = useSearchParams()
  const schoolName: string = searchParams.get('sn') || ''
  const userStudentRelationship: string = user?.relation ?? ''

  // states
  const [schoolAdmins, setSchoolAdmins] = useState<string[]>()
  const [schoolScheduleGroupNames, setSchoolScheduleGroupNames] = useState<string[]>([])
  const [schoolStatus, setSchoolStatus] = useState<string>('')
  const [schoolCode, setSchoolCode] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()
  const [loading, setLoading] = useState<boolean>(false) // Track loading

  // hooks
  useEffect(() => {
    (async () => {
      setLoading(true)
      const { msg, data, error } = await getSchool(schoolName)
      if (error) setMsg(msg)
      else if (data) {
        setSchoolStatus(data.status as string)
        setSchoolAdmins(data?.schoolAdmins as string[])
        setSchoolScheduleGroupNames(
          (data?.schoolScheduleGroups as any[])?.map((group: any) => group.schoolScheduleGroupName),
        )
      }
      setLoading(false)
    })()
  }, [schoolName])

  // handlers
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    setLoading(true)
    const { msg, error } = await verifySchoolCode(schoolName, schoolCode)
    if (error) setMsg(msg)
    else {
      // Save school name and code for following steps
      setSessionItem('x-pool-sn', schoolName)
      setSessionItem('x-pool-sa', schoolAdmins)
      setSessionItem('x-pool-sc', schoolCode)
      setSessionItem('x-pool-ssgn', schoolScheduleGroupNames)
      if (schoolScheduleGroupNames.length > 1) {
        navigate('/add/school/schedule')
      } else {
        setSessionItem('x-pool-sg', schoolScheduleGroupNames[0])
        navigate('/add/school')
      }
    }
    setLoading(false)
  }

  const onSubmit = async (): Promise<any> => {
    setLoading(true)
    const { msg, error } = await verifySchoolCode(schoolName, schoolCode)
    if (error) setMsg(msg)
    else {
      // Register Student
      const { msg, data, error } = await registerStudent(
        schoolName,
        schoolCode,
        userStudentRelationship,
        schoolScheduleGroupNames[0],
        { address: '' },
        { address: '' },
      )
      if (error) setMsg(msg)
      else {
        // Return
        const id: string = (data?.studentId as string) ?? ''
        onAdd(id, () => {
          navigate(`/students?id=${id}`)
        })
      }
    }
    setLoading(false)
  }

  if (schoolStatus === 'verify_code') {
    const isFinalStep: boolean = schoolScheduleGroupNames.length === 1 && userStudentRelationship ? true : false
    return (
      <>
        {/***************** Display progress and error *****************/}
        {loading && <LinearProgress />}
        {msg && (
          <ToastComponent
            style={msg?.style}
            heading={msg?.heading}
            text={msg?.text}
            onClose={() => setMsg(undefined)}
          />
        )}
        {/***************** Wizard *****************/}
        <WizardComponent
          onBack={onBack}
          title={'Enter Your School Code'}
          disable={!schoolCode}
          btnText={isFinalStep ? 'Save' : 'Next'}
          onClick={() => (isFinalStep ? onSubmit() : onNext())}
          data_testId={isFinalStep ? 'save-btn-testid' : 'next-btn-testid'}
        >
          <InputMobileComponent
            type='text'
            placeholder='School code'
            data_testid='school_verification_code_testid'
            onChange={(v: string) => setSchoolCode(v.trimEnd())}
          />
          {schoolAdmins && schoolAdmins?.length >= 1 && (
            <Box pt={1}>
              <div className='text-gray-400 text-sm'>
                No school code? Contact your school admin{` at ${schoolAdmins[0].toString()}`}
              </div>
            </Box>
          )}
        </WizardComponent>
      </>
    )
  } else if (schoolStatus === 'verify_manual') {
    return (
      <WizardComponent
        onBack={onBack}
        title={'Self-Registration Not Enabled'}
        subtitle={`Please contact your school administrator to complete the registration process.`}
      >
        <></>
      </WizardComponent>
    )
  } else if (schoolStatus === 'disabled') {
    return (
      <WizardComponent
        onBack={onBack}
        title={'School Not Enrolled'}
        subtitle={`Ask school/pta to enroll for carpool.school service.`}
      >
        <></>
      </WizardComponent>
    )
  } else if (schoolStatus === 'missing') {
    return (
      <WizardComponent
        onBack={onBack}
        title={'School Missing'}
        subtitle={`Contact us at support@carpool.school to add your school.`}
      >
        <></>
      </WizardComponent>
    )
  } else return <LinearProgress />
}

export default Index
