import { http, httpBearerAuth, httpCodeAuth, processError } from 'utils/requests'
import { ResponseProps, ScheduleGroupProps, SubscriptionProps } from 'utils/types'
import { getItem } from './constants'

export const getSchool = async (name: string): Promise<ResponseProps> => {
  try {
    const { data } = await http('get', `/api/schools/${name.toLowerCase()}`)
    const { isSchoolConfigured, isCarpoolActive, isSchoolCode, schoolAdmins, schoolLocation, schoolScheduleGroups } =
      data
    const status =
      isCarpoolActive === true
        ? isSchoolCode === true
          ? 'verify_code'
          : 'verify_manual'
        : isSchoolConfigured === true
          ? 'disabled'
          : 'missing'
    return { data: { status, schoolAdmins, schoolLocation, schoolScheduleGroups } }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const verifySchoolCode = async (name: string, code: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/verifySchool`, '', { name, code })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const verifySchoolAdminEmail = async (name: string, adminEmail: string): Promise<ResponseProps> => {
  try {
    const { data } = await http('post', `/api/verifySchool`, '', { name, adminEmail })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const verifySchoolAdminCode = async (
  name: string,
  adminEmail: string,
  adminCode: string,
  adminSignInDurationSecs: any,
): Promise<ResponseProps> => {
  try {
    const { data } = await http('post', `/api/verifySchool`, '', {
      name,
      adminEmail,
      adminCode,
      adminSignInDurationSecs,
    })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const signoutSchoolAdmin = async (): Promise<ResponseProps> => {
  try {
    // FIXME: After server starts accepting credentials in header in addition to body, switch this to use httpCodeAuth utility functon. Until then dip into session state here
    const { data } = await http('post', `/api/verifySchool`, '', {
      name: getItem('x-pool-sn'),
      adminEmail: getItem('x-pool-sae'),
      adminCode: getItem('x-pool-sac'),
      adminSignInDurationSecs: 0,
    })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getSchoolPool = async (name: string, date?: string): Promise<ResponseProps> => {
  try {
    let url = `/api/schools/${name.toLowerCase()}/pool`
    if (date) url = url + `?poolDate=${date}`
    const { data } = await httpBearerAuth('get', url)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getSchoolUsers = async (name: string): Promise<ResponseProps> => {
  try {
    const url = `/api/schools/${name.toLowerCase()}/users`
    const { data } = await httpCodeAuth('get', url)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const getUsers = async (name: string): Promise<ResponseProps> => {
  try {
    const url = `/api/users`
    const { data } = await httpBearerAuth('get', url, name)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

export const updateSchool = async (
  name: string,
  isCarpoolActive: boolean,
  schoolScheduleGroups?: ScheduleGroupProps[],
  schoolAdmins?: string[],
  schoolActivities?: string[],
  schoolCode?: string | null,
  schoolSubscription?: SubscriptionProps | object,
): Promise<ResponseProps> => {
  try {
    const url = `/api/schools/${name.toLowerCase()}`
    const { data } = await httpCodeAuth('patch', url, {
      isCarpoolActive,
      schoolScheduleGroups,
      schoolAdmins,
      schoolActivities,
      schoolCode,
      schoolSubscription,
    })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}
