export type PositionProps = {
  lat: number
  lng: number
}

export type LocationProps = {
  address: string
  lat?: number
  lng?: number
  placeId?: string
}

export type DateTimeProps = {
  [date: string]: number
}

export type ScheduleGroupProps = {
  schoolScheduleGroupName: string
  schoolScheduleGroupStartTime: number
  schoolScheduleGroupEndTime: number
}

export type SchoolStatProps = {
  totalUsers: number
  pickupUsers: number
  dropoffUsers: number
  inactiveUsers: number
  pickupUsersByCity: Record<string, number>
  dropoffUsersByCity: Record<string, number>
  pickupUsersByPostalCode: Record<string, number>
  dropoffUsersByPostalCode: Record<string, number>
}

export type PaymentProps = {
  currentPeriodStart: number
  currentPeriodEnd: number
  paymentType: string
  cardLast4: string
  cardBrand: string
  cardExpMonth: string
  cardExpYear: string
}
export type SubscriptionProps = {
  maxRegistrations?: number
  billingCycle?: string
  paymentMethodId?: string
  paymentMethod?: PaymentProps
}

export type NotificationProps = {
  isRemind?: boolean
  isNotify?: boolean
  isSmsNotifications?: boolean
  devices?: string[]
}

// Vehicle Interfaces
export enum VehicleProps {
  NONE = 0,
  HIRE_TRANSPORT_SERVICE = 1,
}

// React Bootstrap Interfaces
export interface MessageProps {
  style?: string
  heading?: string
  text?: string
  timeout?: number
}

export interface TabProps {
  key: string
  title: JSX.Element | string
  children: JSX.Element
}

export interface iconSizeProps {
  width: number
  height: number
}

// GeoLocation Interfaces
export interface MarkerProps {
  position: PositionProps
  iconPath?: string
  iconFillColor?: string
  label?: string
  infoWindowId?: string
  infoWindowContent?: string
  circleRadius?: number
  iconSize?: iconSizeProps
  draggable?: boolean
  zIndex?: any
}

// Modal Interfaces
export type ModalProps = {
  title: string
  className?: string
  children: JSX.Element
  onClose: () => void
}

// Calendar Interfaces
export enum CalendarState {
  DISABLE = 'disabled',
  ENABLE = 'enabled',
}

export type CalendarEventProps = {
  from: Date
  eventState?: CalendarState
}

export type CalendarProps = {
  from: Date
  userAction: CalendarState
}

/**
 * API PROPS
 */
export interface ResponseProps<T = Record<string, unknown>, E = Record<string, unknown>> {
  msg?: MessageProps
  data?: T
  error?: E
}

/**
 * FAMILY USER PROPS
 */
export type StudentProps = {
  studentId: string
  schoolName: string
  isCarpoolActive?: boolean
  isSchoolCode?: boolean
  isSchoolSubscription?: boolean
  schoolLocation: LocationProps
  schoolStats: SchoolStatProps
  schoolScheduleGroups: ScheduleGroupProps[]
  schoolActivities: string[]
  schoolPoolRadius?: number
  userRelationship: string
  scheduleGroupName: string
  studentName?: string
  isStudentSubscription?: boolean
  pickupLocation?: LocationProps
  dropoffLocation?: LocationProps
  pickupTimes?: DateTimeProps
  dropoffTimes?: DateTimeProps
  dropoffTimeDefault?: number
  studentActivities: string[]
  pickupVehicle?: VehicleProps
  pickupSeatsFilled?: number
  pickupSeatsAvailable?: number
  pickupComments?: string
  dropoffVehicle?: VehicleProps
  dropoffSeatsFilled?: number
  dropoffSeatsAvailable?: number
  dropoffComments?: string
  notificationSettings?: NotificationProps
}

export type UserProps = {
  phoneNumber: string
  name?: string
  firstName?: string
  lastName?: string
  relation?: string
  status?: string
  isEnabled?: boolean
  userSubscription?: SubscriptionProps
  createdAt?: string
  updatedAt?: string
}

export type SchoolPoolStudentProps = {
  userName: string
  userPhoneNumber: string
  userRelationship: string
  studentId: string
  studentName: string
  seatsFilled?: number
  seatsAvailable?: number
  comments?: string
  position?: PositionProps
  dropoffTimeDefault?: number
  dropoffTime: number
  studentActivities: string[]
  scheduleGroupName: string
  readAt: string
}

export type SchoolPoolProps = {
  schoolName: string
  schoolLocation: LocationProps
  schoolActivities: string[]
  schoolScheduleGroups?: ScheduleGroupProps[]
  students: SchoolPoolStudentProps[]
}

/**
 * ADMIN USER PROPS
 */
export type SchoolUsersStudentProps = {
  userName: string
  userPhoneNumber: string
  userRelationship: string
  studentId: string
  studentName?: string
  isPickup: boolean
  isDropoff: boolean
  studentActivities: string[]
  scheduleGroupName: string
  createdAt?: string
  updatedAt?: string
  readAt?: string
}

export type SchoolUsersProps = {
  schoolName: string
  isCarpoolActive: boolean
  schoolCode?: string
  schoolLocation?: LocationProps
  schoolScheduleGroups?: ScheduleGroupProps[]
  schoolSubscription: SubscriptionProps
  schoolActivities: string[]
  schoolAdmins?: string[]
  schoolStats?: SchoolStatProps
  students?: SchoolUsersStudentProps[]
}

export type AddUserProps = {
  userName: string
  userPhoneNumber: string
  scheduleGroupName: string
}

export type UpdateStudentProps = AddUserProps & {
  userRelationship?: any
  pickupLocation?: any
  dropoffLocation?: any
}
