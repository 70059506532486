import { Checkbox, FormControlLabel } from '@mui/material'
import AvatarComponent from 'components/avatar'
import ButtonMobileComponent from 'components/button-mobile'
import SelectMobileComponent from 'components/select-mobile'
import { getDistance } from 'geolib'
import Arrow from 'images/arrow_forward.png'
import React, { useEffect, useState } from 'react'
import { RequiredFields } from 'types/CommonTypes'
import { RoomType, RoomUsersOutputProps, UserRoomsOutputProps } from 'types/RoomTypes'
import { UsersOutputProps } from 'types/UserTypes'
import { METERS_TO_MILES, titleCaseSchoolNameForUI } from 'utils/constants'
import { PositionProps } from 'utils/types'

// Mock Data Types
interface RoomAddUsersComponentProps {
  users: UsersOutputProps[] // all users
  room: UserRoomsOutputProps // room to add users
  roomUsers: RoomUsersOutputProps[] // all room users
  userId: string // current user creating/adding
  onAddRoomUsers: (id: string, users: string[]) => Promise<void>
}

interface CardComponentProps {
  isChecked: boolean
  avatar?: JSX.Element
  title: string
  subtitle?: string
  body?: string
  onChange: (isChecked: boolean) => void
}

const CardComponent = (props: CardComponentProps): JSX.Element => {
  const { isChecked, avatar, title, subtitle, body, onChange } = props ?? {}

  return (
    <div className={`flex flex-col my-4`}>
      <FormControlLabel
        control={
          <Checkbox
            value={isChecked}
            onChange={(e) => onChange(e.target.checked)}
            sx={{
              strokeWidth: 1,
              '&.Mui-checked': {
                color: '#EBD76F',
              },
            }}
          />
        }
        label={
          <div className='flex gap-2 flex-1'>
            {avatar}
            <div className='flex flex-col'>
              <div className='flex items-center gap-1'>
                <span className='text-[#474747] text-sm font-medium'>{title}</span>
                {subtitle && (
                  <>
                    <span className='w-[1px] bg-gray-300 h-[20px]' />
                    <span className='text-[#797979] text-xs font-medium'>{subtitle}</span>
                  </>
                )}
              </div>
              <span className='text-[#FF7900] text-sm font-normal'>{body}</span>
            </div>
          </div>
        }
      />
    </div>
  )
}

const RoomAddUsersComponent: React.FC<RoomAddUsersComponentProps> = ({
  users,
  room,
  roomUsers,
  userId,
  onAddRoomUsers,
}) => {
  const { roomType, roomId, roomCommunityId } = room as RequiredFields<UserRoomsOutputProps, 'roomCommunityId'>
  const filters: string[] = [roomCommunityId]

  // State for selected school and room type filter
  const [selectedFilter, setSelectedFilter] = useState<string>(titleCaseSchoolNameForUI(filters[0]))
  const [filteredUsers, setFilteredUsers] = useState<CardComponentProps[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set())

  // hooks
  useEffect(() => {
    const cards: (CardComponentProps & { distance: number })[] = []
    const selectedFilterUsers: UsersOutputProps[] = users.filter(
      (v) => selectedFilter === titleCaseSchoolNameForUI(v.userCommunityId),
    )
    const excludedUsers: Set<string> = new Set<string>(roomUsers.map((v) => v.userId))
    const currentUserPickupLocation: PositionProps | undefined = selectedFilterUsers.find(
      (v) => userId === v.userId && v.userCommunityStudentPickupLocation,
    )?.userCommunityStudentPickupLocation
    const currentUserDropoffLocation: PositionProps | undefined = selectedFilterUsers.find(
      (v) => userId === v.userId && v.userCommunityStudentDropoffLocation,
    )?.userCommunityStudentDropoffLocation
    selectedFilterUsers
      .filter((v) => userId !== v.userId)
      .forEach((v: UsersOutputProps) => {
        const { userName, userRelationship, userCommunityStudentPickupLocation, userCommunityStudentDropoffLocation } =
          v
        // Only show users that are not existing members for private groups
        const isMember: boolean = excludedUsers.has(v.userId)
        const isRoomTypeFound: boolean = roomType === RoomType.GROUP
        // Only show users that have matching morning and/or afternoon caprool requests
        // and use the afternoon carpool distance when the you have both matching
        let distance: number | undefined
        if (currentUserDropoffLocation && userCommunityStudentDropoffLocation)
          distance = getDistance(currentUserDropoffLocation, userCommunityStudentDropoffLocation)
        else if (currentUserPickupLocation && userCommunityStudentPickupLocation)
          distance = getDistance(currentUserPickupLocation, userCommunityStudentPickupLocation)
        if (!isMember && isRoomTypeFound && distance) {
          cards.push({
            isChecked: false,
            avatar: <AvatarComponent name={userName} />,
            title: userName,
            subtitle: userRelationship,
            body: Math.round(distance * METERS_TO_MILES * 10) / 10 + ' miles away',
            onChange: (isChecked: boolean) =>
              isChecked
                ? setSelectedUsers((prev) => new Set(prev).add(v.userId))
                : setSelectedUsers((prev) => {
                    const curr = new Set(prev)
                    curr.delete(v.userId)
                    return curr
                  }),
            distance,
          })
        }
      })
    setSelectedUsers(new Set())
    setFilteredUsers(cards.sort((a, b) => a.distance - b.distance))
  }, [userId, users, selectedFilter, roomType, roomUsers])

  // Filtered Rooms
  return (
    <>
      {/* **************** Body **************** */}
      <div className='flex flex-col flex-1 self-stretch px-5 gap-2 mb-[120px]'>
        {/***************** Show Community *****************/}
        <SelectMobileComponent
          isRequired={true}
          isDisabled={true}
          options={filters.map((v) => titleCaseSchoolNameForUI(v))}
          value={selectedFilter}
          onChange={(v: string) => setSelectedFilter(v)}
        />
        {/* **************** User List **************** */}
        {filteredUsers.map((room: CardComponentProps, index: number) => (
          <CardComponent
            key={index}
            isChecked={room.isChecked}
            avatar={room.avatar}
            title={room.title}
            subtitle={room.subtitle}
            body={room.body}
            onChange={room.onChange}
          />
        ))}
      </div>
      {/* **************** Footer **************** */}
      <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-3'>
        <div className='border-b border-gray-200'></div>
        <div className='pt-5 pb-10 px-5'>
          <ButtonMobileComponent
            text='Submit'
            color='bg-custom-yellow'
            width='w-full'
            disabled={!selectedUsers?.size}
            onClick={() => onAddRoomUsers(roomId, [...Array.from(selectedUsers)])}
            iconPosition='right'
            icon={Arrow}
          />
        </div>
      </div>
    </>
  )
}

export default RoomAddUsersComponent
