import { MessageSendIcon } from 'components/icons'
import React, { useEffect, useRef, useState } from 'react'

interface RoomMsgTextAreaComponentProps {
  onSubmit: (newValue: string) => void
  suggestedMessages?: string[] // Optional prop for suggested messages
}

const RoomMsgTextAreaComponent: React.FC<RoomMsgTextAreaComponentProps> = ({ onSubmit, suggestedMessages = [] }) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const [value, setValue] = useState<string>('')
  const [selectedSuggestion, setSelectedSuggestion] = useState<string | null>(null) // Track selected message

  useEffect(() => {
    // Initialize height once on mount
    const target = textareaRef.current
    if (target) {
      target.style.height = '48px' // Initial height
    }
  }, [])

  const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const target = e.currentTarget
    target.style.height = '48px' // Reset to initial height to recalculate
    target.style.height = `${Math.max(target.scrollHeight, 48)}px` // Set height based on content
    setValue(target.value)
    setSelectedSuggestion(null) // Clear the selected suggestion when typing
  }

  const handleSuggestionClick = (suggestion: string) => {
    setValue(suggestion)
    setSelectedSuggestion(suggestion)
  }

  return (
    <div className='w-full'>
      {/* Suggested Messages */}
      {suggestedMessages.length > 0 && (
        <div
          className='flex flex-nowrap overflow-x-auto overflow-y-hidden whitespace-nowrap mb-4 gap-3'
          style={{
            scrollbarWidth: 'none', // Hide scrollbar on Firefox
          }}
        >
          {suggestedMessages.map((suggestion, index) => (
            <span
              key={index}
              onClick={() => handleSuggestionClick(suggestion)} // Set textarea value and highlight on click
              className={`text-xs rounded-xl px-2 py-1 cursor-pointer ${
                selectedSuggestion === suggestion
                  ? 'bg-custom-yellow text-gray-800'
                  : 'bg-light-yellow border-custom-yellow border text-gray-800'
              }`}
            >
              {suggestion}
            </span>
          ))}
        </div>
      )}

      {/* Text Area and Send Button */}
      <div className='flex items-center w-full gap-3'>
        <textarea
          ref={textareaRef}
          className='w-full rounded-lg border border-gray-300 bg-white shadow-xs focus:outline-none h-36 px-6 py-3'
          style={{ overflow: 'hidden', resize: 'none', minHeight: '48px' }}
          onInput={handleInput}
          value={value}
          onChange={(e) => setValue(e.target.value)} // Update value on change
        />
        {value.trim().length > 0 && (
          <button
            onClick={() => {
              // Handle send action
              if (textareaRef.current) {
                onSubmit(value)
                setValue('') // Clear the textarea after submit
                setSelectedSuggestion(null) // Clear selected suggestion after submit
                textareaRef.current.value = ''
                textareaRef.current.style.height = '48px' // Reset to initial height
              }
            }}
          >
            <MessageSendIcon />
          </button>
        )}
      </div>

      {/* Hide scroll bar for Webkit browsers */}
      <style>
        {`
          .overflow-x-auto::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
    </div>
  )
}

export default RoomMsgTextAreaComponent
