import { Box } from '@mui/material'
import L, { Icon } from 'leaflet'
import 'leaflet-control-geocoder'
import 'leaflet-control-geocoder/dist/Control.Geocoder.css'
import 'leaflet-control-geocoder/dist/Control.Geocoder.js'
import 'leaflet/dist/leaflet.css'
import React, { useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { LocationProps, MarkerProps } from '../../utils/types'
import './index.css'
import { isWeb } from 'utils/constants'

interface MapComponentProps {
  centerMarker: MarkerProps
  markers?: MarkerProps[]
  onAddressUpdate?: (location: LocationProps) => void
  openFull?: any // openFull is a toggle button of the full screen mode
  mapHeight?: any // mapHeight is used for current pin location maps (morning and afternoon carpool)
  customHeight?: any // height of the map in web and mobile versions
  borderRadius?: any
}

interface CustomMarkerProps {
  marker: MarkerProps
  mapRef: React.MutableRefObject<L.Map | null>
  onAddressUpdate?: (location: LocationProps) => void
}

const CustomMarker: React.FC<CustomMarkerProps> = (props) => {
  const { marker, mapRef, onAddressUpdate } = props
  const { position, iconPath, iconSize, draggable, infoWindowContent, zIndex } = marker

  const icon = new Icon({
    iconUrl: iconPath,
    iconSize: [iconSize?.width || 40, iconSize?.height || 40],
  })

  const handleDragEnd = (event: L.DragEndEvent) => {
    if (draggable && event.target instanceof L.Marker && onAddressUpdate) {
      const latLng = event.target.getLatLng()

      // Ensure geocoder is available before using it
      const geocoder = (L.Control as any).Geocoder.nominatim()
      const map = mapRef.current
      if (map) {
        const crsScale = map.options.crs?.scale(map.getZoom() || 0)
        geocoder.reverse(latLng, crsScale, (results: any) => {
          const result = results[0]
          if (result) {
            const locationProps: LocationProps = {
              address: result.name,
              lat: latLng.lat,
              lng: latLng.lng,
            }
            onAddressUpdate(locationProps)
          }
        })
      }
    }
  }

  // Ensure infoWindowContent is a string to avoid TypeScript errors
  const content = typeof infoWindowContent === 'string' ? infoWindowContent : ''

  return (
    <Marker
      position={[position.lat, position.lng]}
      icon={icon}
      draggable={draggable}
      zIndexOffset={zIndex} // Set zIndexOffset based on the marker's zIndex
      eventHandlers={{ dragend: handleDragEnd }}
    >
      <Popup>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Popup>
    </Marker>
  )
}

const MapComponent: React.FC<MapComponentProps> = (props) => {
  const { centerMarker, markers, onAddressUpdate, openFull, mapHeight, customHeight, borderRadius } = props
  const [allMarkers, setAllMarkers] = useState<MarkerProps[]>()
  const mapRef = useRef<L.Map | null>(null)
  const defaultZoom = 12
  const maxZoom = 15

  useEffect(() => {
    const css = `
      .leaflet-control-attribution {
        display: none;
      }
    `
    const style = document.createElement('style')
    style.appendChild(document.createTextNode(css))
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [])

  useEffect(() => {
    setAllMarkers([centerMarker, ...(markers || [])])
  }, [centerMarker, markers])

  // React leaflet child components are immutable
  // https://react-leaflet.js.org/docs/api-components/#props
  // Hence we need a key to re-render when markers change
  // and a separate map for full screen view
  return (
    <>
      {/* openFull - true, it will call this map component having 100vh height by clicking on full screen button */}
      {openFull && (
        <Box sx={{ transition: 'height 0.2s ease' }}>
          <MapContainer
            key={onAddressUpdate ? undefined : JSON.stringify(allMarkers)} // To refresh map on marker update in read only mode i.e. when address update disabled
            center={[centerMarker.position.lat, centerMarker.position.lng]}
            zoom={defaultZoom}
            maxZoom={maxZoom}
            ref={mapRef}
            zoomControl={isWeb ? true : false} // Disable zoom controls on mobile since we have pinch and zoom
            style={{ height: '100vh', zIndex: 0, transition: 'height 0.6s ease' }}
          >
            <TileLayer url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png' />
            {allMarkers?.map((marker: MarkerProps, i: number) => (
              <CustomMarker key={i} marker={marker} onAddressUpdate={onAddressUpdate} mapRef={mapRef} />
            ))}
          </MapContainer>
        </Box>
      )}
      {/* openFull - false, it will call this map component having map height condition */}
      {!openFull && (
        <Box sx={{ transition: 'height 0.2s ease' }}>
          <MapContainer
            key={onAddressUpdate ? undefined : JSON.stringify(allMarkers)} // To refresh map on marker update in read only mode i.e. when address update disabled
            center={[centerMarker.position.lat, centerMarker.position.lng]}
            zoom={defaultZoom}
            maxZoom={maxZoom}
            ref={mapRef}
            zoomControl={isWeb ? true : false} // Disable zoom controls on mobile since we have pinch and zoom
            style={{
              height: mapHeight ? customHeight : '50vh',
              zIndex: 0,
              transition: 'height 0.6s ease',
              borderRadius: borderRadius ?? '0px',
            }}
            // MapHeight (true) - 90vh represents the current pin location maps, while MapHeight (false) - 50vh is for the initial dashboard component without full-screen mode.
          >
            <TileLayer url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png' />
            {allMarkers?.map((marker: MarkerProps, i: number) => (
              <CustomMarker key={i} marker={marker} onAddressUpdate={onAddressUpdate} mapRef={mapRef} />
            ))}
          </MapContainer>
        </Box>
      )}
    </>
  )
}

export default MapComponent
