import { DefaultProfileIcon } from 'components/icons'
import { Link } from 'react-router-dom'

interface MenuProfileMobileComponentProps {
  name: string
  relationship?: string
  Image?: React.FC
  href: string
}

const MenuProfileMobileComponent = (props: MenuProfileMobileComponentProps): JSX.Element => {
  const { name, relationship, Image, href } = props ?? {}

  return (
    <div className='flex justify-end items-start gap-3 self-stretch px-5 mt-5'>
      {/* Image Container */}
      <div className='flex justify-center items-center w-14 h-14'>{Image ? <Image /> : <DefaultProfileIcon />}</div>

      {/* Text Content */}
      <div className='flex flex-col items-start gap-0.5 flex-1'>
        <span className='text-gray-800 text-md font-semibold leading-6'>{name}</span>
        <span className='text-gray-400 text-sm font-normal leading-5'>{relationship}</span>
      </div>

      {/* Edit Icon Container */}
      <Link to={href}>
        <div
          className='flex justify-center items-center gap-1.5 w-8 h-8 p-0.5 rounded-full bg-yellow-200'
          data-testid='edit-profile-testid'
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
            <path
              d='M11.7156 7.51667L12.4822 8.28333L4.93223 15.8333H4.16557V15.0667L11.7156 7.51667ZM14.7156 2.5C14.5072 2.5 14.2906 2.58333 14.1322 2.74167L12.6072 4.26667L15.7322 7.39167L17.2572 5.86667C17.5822 5.54167 17.5822 5.01667 17.2572 4.69167L15.3072 2.74167C15.1406 2.575 14.9322 2.5 14.7156 2.5ZM11.7156 5.15833L2.4989 14.375V17.5H5.6239L14.8406 8.28333L11.7156 5.15833Z'
              fill='#474747'
            />
          </svg>
        </div>
      </Link>
    </div>
  )
}

export default MenuProfileMobileComponent
