import Tooltip from '@mui/material/Tooltip'
import { useState } from 'react'
import './index.css'

const CustomArrowIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      d='M2.90245 4.62445L1.57495 5.95945L8.99995 13.377L16.4249 5.95195L15.0974 4.62445L8.99995 10.722L2.90245 4.62445Z'
      fill='#A3A3A3'
    />
  </svg>
)

const SelectMobileComponent = (props: any): JSX.Element => {
  const { isDisabled, label, tooltip, options, defaultValue, value, onChange, isError, helperText, data_testid } =
    props ?? {}

  const [isOpen, setIsOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(defaultValue || value || '')

  const handleSelectChange = (option: string) => {
    setSelectedValue(option)
    onChange(option)
    setIsOpen(false)
  }

  return (
    <div className='relative'>
      {label && <label className='text-gray-700 text-sm font-medium'>{label}</label>}
      <div
        className={`relative`}
        style={{
          boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1)',
          border: '1px solid #E5E5E5',
          borderRadius: '8px',
          marginTop: '4px',
        }}
      >
        <div
          className={`flex justify-between items-center p-2 cursor-pointer ${
            isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } ${isError ? 'border-red-500' : 'border-gray-300'}  text-gray-900`}
          onClick={() => !isDisabled && setIsOpen(!isOpen)}
          data-testid={data_testid}
        >
          <span>{selectedValue || 'Select an option'}</span>
          <CustomArrowIcon />
        </div>
        {isOpen && (
          <div className='absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg'>
            {options.map((option: string) => (
              <div
                key={option}
                className={`p-2.5 cursor-pointer hover:bg-gray-100 ${option === selectedValue ? 'bg-gray-200' : ''}`}
                onClick={() => handleSelectChange(option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
      {helperText && <p className={`mt-1 text-sm ${isError ? 'text-red-500' : 'text-gray-400'}`}>{helperText}</p>}
      {tooltip && (
        <Tooltip title={tooltip} followCursor disableHoverListener>
          <div className='absolute -top-4 left-0 bg-gray-700 text-white text-xs p-2 rounded-lg'>{tooltip}</div>
        </Tooltip>
      )}
    </div>
  )
}

export default SelectMobileComponent
