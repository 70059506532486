import '@fontsource/figtree/300.css'
import '@fontsource/figtree/400.css'
import '@fontsource/figtree/500.css'
import '@fontsource/figtree/700.css'
import CssBaseline from '@mui/material/CssBaseline'
import LinearProgress from '@mui/material/LinearProgress'
import { extendTheme, ThemeProvider } from '@mui/material/styles'
import type {} from '@mui/x-data-grid/themeAugmentation'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AwsRum, AwsRumConfig } from 'aws-rum-web'
import ErrorBoundary from 'components/error'
import { WithAdminNav, WithFamilyNav, WithNav, WithoutNav } from 'components/navbar'
import { Suspense, useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Add from 'routes/add'
import AddSchool from 'routes/add-school'
import AddSchoolSchedule from 'routes/add-school-schedule'
import AddVerifySchool from 'routes/add-verifyschool'
import SchoolDashboard from 'routes/admin-school-dashboard'
import SchoolSettings from 'routes/admin-school-settings'
import SchoolUsers from 'routes/admin-school-users'
import AdminSignIn from 'routes/admin-signin'
import AdminSignOut from 'routes/admin-signout'
import Afternoon from 'routes/afternoon'
import AfternoonMobile from 'routes/afternoon-mobile'
import Change from 'routes/change'
import ChangeMobile from 'routes/change-mobile'
import Dashboard from 'routes/dashboard'
import DashboardMobile from 'routes/dashboard-mobile'
import Home from 'routes/home'
import HomeMobile from 'routes/home-mobile'
import Messaging from 'routes/messaging'
import Morning from 'routes/morning'
import MorningMobile from 'routes/morning-mobile'
import Profile from 'routes/profile'
import ProfileMobile from 'routes/profile-mobile'
import Reset from 'routes/reset'
import Set from 'routes/set'
import Settings from 'routes/settings'
import SettingsMobile from 'routes/settings-mobile'
import SignIn from 'routes/signin'
import SignOut from 'routes/signout'
import SignUp from 'routes/signup'
import SignUpCreateUser from 'routes/signup-createuser'
import SignUpSchoolSchedule from 'routes/signup-school-schedule'
import SignUpVerifySchool from 'routes/signup-verifyschool'
import SignUpVerifyUser from 'routes/signup-verifyuser'
import SystemDashboard from 'routes/system-dashboard'
import { clearItems, getItem, getSessionItem, isWeb, parseJwt, setItem, setSessionItem } from 'utils/constants'
import { getDeviceId, registerNotifications } from 'utils/notifications'
import { getSchoolUsers } from 'utils/schools'
import { getStudents } from 'utils/students'
import { SchoolUsersProps, StudentProps, UserProps } from 'utils/types'
import { getUser } from 'utils/users'
import './index.css'

const awsRumClient = () => {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: 'arn:aws:iam::879562289751:role/RUM-Monitor-us-east-1-879562289751-8815522963961-Unauth',
      identityPoolId: 'us-east-1:ae0db776-5c46-45af-b6a4-9ff4c9671aa5',
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false,
    }

    const APPLICATION_ID: string = '7b3ae7f8-ba74-4e3e-a1b0-06d23c34afe3'
    const APPLICATION_VERSION: string = '1.0.0'
    const APPLICATION_REGION: string = 'us-east-1'

    new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config)
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: { main: '#010101' },
        secondary: { main: '#eed75a' },
        warning: { main: '#8f8136' },
        success: { main: '#EBD76F' },
        info: { main: '#A3A3A3' },
      },
    },
  },
  typography: {
    fontFamily: 'Figtree',
    fontSize: 14, // Default font size
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {},
      },
    },
    MuiContainer: {
      defaultProps: { maxWidth: 'xl' },
    },
  },
})

const ifAdminSignedIn = (): boolean => !!getItem('x-pool-sac')
const ifSignedIn = (): boolean => !!getItem('tokens')?.IdToken
const ifLocalhost = (): boolean => window.location.hostname === 'localhost'

// Protected route components are only rendered for signed in users. Signed out users are redirected to sign in page.
const ProtectedAdminRoute = ({ outlet }: { outlet: JSX.Element }): JSX.Element => {
  if (ifAdminSignedIn()) return outlet
  else return <Navigate to={{ pathname: '/admin/signin' }} />
}
const ProtectedRoute = ({ outlet }: { outlet: JSX.Element }): JSX.Element => {
  if (ifSignedIn()) return outlet
  else return <Navigate to={{ pathname: '/signin' }} />
}
// Unprotected route components are only rendered for signed out users. Signed in users are redirected to student dashboard.
const UnprotectedRoute = ({ outlet }: { outlet: JSX.Element }): JSX.Element => {
  if (!ifSignedIn()) return outlet
  else return <Navigate to='/students' />
}
// Home route is special case of unprotected route. Home component is only rendered on locally hosted app. Cloud hosted webapps are redirected to external home page.
const UnprotectedHomeRoute = ({ outlet }: { outlet: JSX.Element }): JSX.Element => {
  if (!ifSignedIn()) {
    if (ifLocalhost()) return outlet
    else return <Navigate to={{ pathname: '/home' }} />
  } else return <Navigate to='/students' />
}

function App(): JSX.Element {
  // State store for signed in user
  const [isAdminSignedIn, setIsAdminSignedIn] = useState<boolean>()
  const [isSignedIn, setIsSignedIn] = useState<boolean>()
  const [schoolUsers, setSchoolUsers] = useState<SchoolUsersProps | null>()
  const [students, setStudents] = useState<StudentProps[]>()
  const [user, setUser] = useState<Pick<UserProps, 'name' | 'relation'>>()
  // cannot use state for the currentStudentId because it needs to persist between refresh
  const currentStudentId: string = getSessionItem('x-pool-csi') || ''
  // Handle user state changes
  // Admin route core handlers
  const handleAdminSignOut = () => {
    clearItems()
    setIsAdminSignedIn(false)
    setSchoolUsers(null)
  }
  const handleAdminSchoolUpdate = (cb?: () => void) => {
    (async () => {
      const schoolName = getItem('x-pool-sn') ?? ''
      const { data, error } = await getSchoolUsers(schoolName)
      if (error) handleAdminSignOut()
      else {
        setSchoolUsers({ ...data, schoolName } as SchoolUsersProps)
        if (cb) cb()
      }
    })()
  }
  const handleAdminSignIn = () => {
    setSchoolUsers(undefined)
    handleAdminSchoolUpdate()
    setIsAdminSignedIn(true)
  }
  // Family route core handlers
  const handleSignOut = () => {
    setItem('tokens', null)
    setIsSignedIn(false)
    setStudents([])
    setUser({})
  }
  const handleStudentUpdate = (cb?: () => void, isUpdateUser?: boolean) => {
    (async () => {
      const { data, error } = await getStudents(getDeviceId())
      if (error) handleSignOut()
      else {
        // Store users students in a global state to pass to various routes
        const s: StudentProps[] = data?.students as StudentProps[]
        setStudents(s?.filter((s) => s.userRelationship !== 'SchoolAdmin'))

        // Backend stores relationshop at per student level instead of user level
        // Use the first students relationship for global user student relationship
        // FIXME: Migrate DB data to use Parent instead of 'Parent/Stepparent', 'Mother', 'Father'
        const sr: string[] = s?.map((s: StudentProps) => s.userRelationship) ?? ['']
        const relation: string = ['Parent/Stepparent', 'Mother', 'Father'].includes(sr[0]) ? 'Parent' : sr[0]

        // Update rest of user user profile conditionally
        if (isUpdateUser) {
          const { IdToken } = getItem('tokens')
          const phoneNumber: string = parseJwt(IdToken).phone_number
          const { data, error } = await getUser(phoneNumber)
          if (error) handleSignOut()
          else {
            // Store users in a global state to pass to various routes
            const u: UserProps = data?.user as UserProps
            setUser({ ...u, relation })
          }
        } else setUser({ ...user, relation })
      }
      if (cb) cb()
    })()
  }
  const handleUserUpdate = (cb?: () => void) => {
    handleStudentUpdate(() => {
      if (cb) cb()
    }, true)
  }
  const handleSignIn = (cb?: () => void) => {
    setStudents(undefined)
    setUser(undefined)
    handleStudentUpdate(cb, true)
    setIsSignedIn(true)
  }
  // Family route derived handlers
  const handleStudentRemove = (si: string, cb?: () => void) => {
    handleStudentUpdate(() => {
      if (currentStudentId === si) setSessionItem('x-pool-csi', '') // if current school was deleted, remove it
      if (cb) cb()
    })
  }
  const handleStudentSelect = (si: string, cb?: () => void) => {
    handleStudentUpdate(() => {
      setSessionItem('x-pool-csi', si) // selected school becomes current
      if (cb) cb()
    })
  }
  const handleStudentAdd = (si: string, cb?: () => void) => {
    handleStudentUpdate(() => {
      setSessionItem('x-pool-csi', si) // added school becomes current
      if (cb) cb()
    })
  }
  const handleSignUp = (cb?: () => void) => {
    handleSignIn(cb)
  }
  // Family route helper handlers
  const handleStudentIdQuery = (studentIdFromQuery: string): string => {
    // If non empty student ID is not present in the query parameters, use the local state
    let csi: string = studentIdFromQuery || currentStudentId
    // If student ID is not valid, use the first student id from remote server
    const studentIdsFromRemoteServer = students?.map((s: StudentProps) => s.studentId) ?? []
    if (!csi || !studentIdsFromRemoteServer.includes(csi)) csi = studentIdsFromRemoteServer[0] || '' // session state needs a string, undefined does not work
    // Update session state with finalized active student id
    setSessionItem('x-pool-csi', csi)
    return csi
  }

  // Register app
  useEffect(() => {
    (async () => await registerNotifications())()
    if (ifAdminSignedIn()) handleAdminSignIn()
    else handleAdminSignOut()
    if (ifSignedIn()) handleSignIn()
    else handleSignOut()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (
    isAdminSignedIn === undefined ||
    isSignedIn === undefined ||
    students === undefined ||
    user === undefined ||
    schoolUsers === undefined
  )
    return <></>

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter>
            <Suspense fallback={<LinearProgress />}>
              <Routes>
                {/* Admin Routes - Single web and mobile responsive page, with Admin NavBar */}
                <Route element={<WithAdminNav />}>
                  <Route path='/admin/signin' element={<AdminSignIn onSignIn={handleAdminSignIn} />} />
                </Route>
                <Route
                  element={
                    <WithAdminNav
                      isSignedIn={isAdminSignedIn}
                      isActive={!!schoolUsers?.isCarpoolActive}
                      schoolName={getItem('x-pool-sn') ?? ''}
                    />
                  }
                >
                  <Route
                    path='/admin/dashboard'
                    element={
                      <ProtectedAdminRoute outlet={<SchoolDashboard schoolStats={schoolUsers?.schoolStats} />} />
                    }
                  />
                  <Route
                    path='/admin/settings'
                    element={
                      <ProtectedAdminRoute
                        outlet={<SchoolSettings schoolUsers={schoolUsers} onUpdate={handleAdminSchoolUpdate} />}
                      />
                    }
                  />
                  <Route
                    path='/admin/users'
                    element={
                      <ProtectedAdminRoute
                        outlet={<SchoolUsers schoolUsers={schoolUsers} onUpdate={handleAdminSchoolUpdate} />}
                      />
                    }
                  />
                  <Route
                    path='/admin/signout'
                    element={<ProtectedAdminRoute outlet={<AdminSignOut onSignOut={handleAdminSignOut} />} />}
                  />
                  <Route
                    path='/admin/*'
                    element={
                      <ProtectedAdminRoute
                        outlet={<SchoolSettings schoolUsers={schoolUsers} onUpdate={handleAdminSchoolUpdate} />}
                      />
                    }
                  />
                </Route>
                {/* App Route - Single web and mobile responsive page, without NavBar */}
                <Route element={<WithoutNav />}>
                  <Route path='/home' element={<Home />} />
                  <Route path='/' element={<UnprotectedHomeRoute outlet={<HomeMobile />} />} />
                </Route>
                {/* App Route - Single web and mobile responsive page, with NavBar */}
                <Route element={isWeb ? <WithNav /> : <WithoutNav />}>
                  {/* System Dashboard */}
                  <Route path='/system/dashboard' element={<ProtectedRoute outlet={<SystemDashboard />} />} />
                  {/* Sign Up Workflow */}
                  <Route path='/set/:pn' element={<UnprotectedRoute outlet={<Set />} />} />
                  <Route path='/reset' element={<UnprotectedRoute outlet={<Reset />} />} />
                  <Route path='/signup' element={<UnprotectedRoute outlet={<SignUp />} />} />
                  <Route path='/signup/verifySchool' element={<UnprotectedRoute outlet={<SignUpVerifySchool />} />} />
                  <Route
                    path='/signup/school/schedule'
                    element={<UnprotectedRoute outlet={<SignUpSchoolSchedule />} />}
                  />
                  <Route path='/signup/createUser' element={<UnprotectedRoute outlet={<SignUpCreateUser />} />} />
                  <Route path='/signup/verifyUser' element={<SignUpVerifyUser onSignUp={handleSignUp} />} />
                  {/* New School Workflow */}
                  <Route path='/add' element={<ProtectedRoute outlet={<Add />} />} />
                  <Route
                    path='/add/verifySchool'
                    element={<ProtectedRoute outlet={<AddVerifySchool user={user} onAdd={handleStudentAdd} />} />}
                  />
                  <Route
                    path='/add/school/schedule'
                    element={<ProtectedRoute outlet={<AddSchoolSchedule user={user} onAdd={handleStudentAdd} />} />}
                  />
                  <Route
                    path='/add/school'
                    element={<ProtectedRoute outlet={<AddSchool onAdd={handleStudentAdd} />} />}
                  />
                  {/* Sign In */}
                  <Route path='/signin' element={<UnprotectedRoute outlet={<SignIn onSignIn={handleSignIn} />} />} />
                  {/* Sign Out */}
                  <Route path='/signout' element={<ProtectedRoute outlet={<SignOut onSignOut={handleSignOut} />} />} />
                </Route>
                {/* App Route - Single web and mobile responsive page, with Family NavBar */}
                <Route
                  element={
                    isWeb ? (
                      <WithFamilyNav
                        isSignedIn={isSignedIn}
                        students={students}
                        currentStudentId={currentStudentId}
                        user={user}
                      />
                    ) : (
                      <WithoutNav />
                    )
                  }
                >
                  {/* Messaging */}
                  <Route
                    path='/messaging'
                    element={
                      <ProtectedRoute
                        outlet={
                          <Messaging
                            tokens={getItem('tokens')}
                            students={students}
                            user={user}
                            onUpdate={handleStudentUpdate}
                          />
                        }
                      />
                    }
                  />
                </Route>
                {/* App Route - Separate web and mobile pages, with NavBar */}
                <Route
                  element={
                    isWeb ? (
                      <WithFamilyNav
                        isSignedIn={isSignedIn}
                        students={students}
                        currentStudentId={currentStudentId}
                        user={user}
                      />
                    ) : (
                      <WithoutNav />
                    )
                  }
                >
                  {isWeb ? (
                    <>
                      {/* Dashboard */}
                      <Route
                        path='/students'
                        element={
                          <ProtectedRoute
                            outlet={
                              <Dashboard
                                tokens={getItem('tokens')}
                                students={students}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                              />
                            }
                          />
                        }
                      />
                      <Route
                        path='/morning'
                        element={
                          <ProtectedRoute
                            outlet={
                              <Morning
                                students={students}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                              />
                            }
                          />
                        }
                      />
                      <Route
                        path='/afternoon'
                        element={
                          <ProtectedRoute
                            outlet={
                              <Afternoon
                                students={students}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                              />
                            }
                          />
                        }
                      />
                      {/* Change School */}
                      <Route
                        path='/change'
                        element={
                          <ProtectedRoute
                            outlet={
                              <Change
                                students={students}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                                onSelect={handleStudentSelect}
                                onRemove={handleStudentRemove}
                              />
                            }
                          />
                        }
                      />
                      {/* User Profile */}
                      <Route
                        path='/profile'
                        element={
                          <ProtectedRoute
                            outlet={
                              <Profile
                                tokens={getItem('tokens')}
                                students={students}
                                user={user}
                                onUpdate={handleUserUpdate}
                              />
                            }
                          />
                        }
                      />
                      {/* User Settings */}
                      <Route
                        path='/settings'
                        element={
                          <ProtectedRoute outlet={<Settings tokens={getItem('tokens')} students={students} />} />
                        }
                      />
                    </>
                  ) : (
                    <>
                      {/* Dashboard */}
                      <Route
                        path='/students'
                        element={
                          <ProtectedRoute
                            outlet={
                              <DashboardMobile
                                tokens={getItem('tokens')}
                                user={user}
                                students={students}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                              />
                            }
                          />
                        }
                      />
                      <Route
                        path='/morning'
                        element={
                          <ProtectedRoute
                            outlet={
                              <MorningMobile
                                students={students}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                              />
                            }
                          />
                        }
                      />
                      <Route
                        path='/afternoon'
                        element={
                          <ProtectedRoute
                            outlet={
                              <AfternoonMobile
                                students={students}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                              />
                            }
                          />
                        }
                      />
                      {/* Change School */}
                      <Route
                        path='/change'
                        element={
                          <ProtectedRoute
                            outlet={
                              <ChangeMobile
                                students={students}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                                onSelect={handleStudentSelect}
                                onRemove={handleStudentRemove}
                              />
                            }
                          />
                        }
                      />
                      {/* User Profile */}
                      <Route
                        path='/profile'
                        element={
                          <ProtectedRoute
                            outlet={
                              <ProfileMobile
                                tokens={getItem('tokens')}
                                students={students}
                                user={user}
                                onUpdate={handleUserUpdate}
                              />
                            }
                          />
                        }
                      />
                      {/* User Settings */}
                      <Route
                        path='/settings'
                        element={
                          <ProtectedRoute outlet={<SettingsMobile tokens={getItem('tokens')} students={students} />} />
                        }
                      />
                    </>
                  )}
                </Route>
                {/* All Other Routes */}
                <Route path='*' element={<Navigate to='/home' />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </LocalizationProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

if (!ifLocalhost()) awsRumClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
