import AvatarComponent from 'components/avatar'
import ButtonMobileComponent from 'components/button-mobile'
import { CreateGroupIcon, JoinGroupIcon, PrivateGroupIcon, PublicGroupIcon, SearchIcon } from 'components/icons'
import InputMobileComponent from 'components/input-mobile'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { RoomType, UserRoomsOutputProps } from 'types/RoomTypes'
import { titleCaseSchoolNameForUI } from 'utils/constants'

// Mock Data Types
interface RoomsComponentProps {
  rooms: UserRoomsOutputProps[] // all rooms
  isShowCommunityName: boolean
}

interface CardComponentProps {
  id: string
  avatar: JSX.Element
  title: string
  subtitle?: string
  body?: string
  footer?: JSX.Element
}

const CardComponent = (props: CardComponentProps): JSX.Element => {
  const { avatar, title, subtitle, body, footer } = props ?? {}

  return (
    <div className={`flex flex-col items-start gap-3 my-4 self-stretch lg:p-2 lg:border lg:rounded-xl`}>
      <div className='flex justify-between items-center self-stretch'>
        <div className='flex items-center gap-2 flex-1'>
          {avatar}
          <div className='flex flex-col'>
            <div className='flex items-center gap-1'>
              <span className='text-[#474747] text-sm font-medium'>{title}</span>
              {subtitle && (
                <>
                  <span className='w-[1px] bg-gray-300 h-[20px]' />
                  {/* <div className='text-[12px] bg-light-yellow py-1 px-2 rounded-xl'>{subtitle}</div> */}
                  <span className='text-[#797979] text-xs font-medium'>{subtitle}</span>
                </>
              )}
            </div>
            <span className='text-[#797979] text-xs font-regular max-w-[250px] overflow-hidden whitespace-nowrap text-ellipsis'>
              {body}
            </span>
          </div>
        </div>
        {footer}
      </div>
    </div>
  )
}

const RoomsComponent: React.FC<RoomsComponentProps> = ({ rooms, isShowCommunityName }) => {
  const filters: string[] = ['All', 'Private chats', 'Private groups', 'Public groups']

  // State for selected school and room type filter
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [selectedFilter, setSelectedFilter] = useState<string>(filters[0])
  const [filteredRooms, setFilteredRooms] = useState<CardComponentProps[]>([])

  // hooks
  useEffect(() => {
    const cards: CardComponentProps[] = []
    rooms.forEach((v: UserRoomsOutputProps) => {
      const {
        roomType,
        roomId,
        roomName,
        roomDescription,
        roomCommunityId,
        roomCommunityActivity,
        roomUpdatedAt,
        isMember,
      } = v
      const today = new Date()
      const isKeywordFound: boolean =
        !searchKeyword ||
        [roomName, roomDescription, roomCommunityId, roomCommunityActivity].some(
          (field) => field && field.toLowerCase().includes(searchKeyword.toLowerCase()),
        )
      const isRoomTypeFound: boolean =
        selectedFilter === 'All' ||
        (selectedFilter === 'Private chats' && roomType === RoomType.PRIVATE) ||
        (selectedFilter === 'Private groups' && roomType === RoomType.GROUP) ||
        (selectedFilter === 'Public groups' && (roomType === RoomType.PUBLIC || roomType === RoomType.ACTIVITY))
      if (isMember && isKeywordFound && isRoomTypeFound) {
        const roomUpdatedAtDate: Date = new Date(roomUpdatedAt)
        const displayDate: string =
          roomUpdatedAtDate.toDateString() === today.toDateString()
            ? roomUpdatedAtDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            : roomUpdatedAtDate.toLocaleDateString('en-US')
        cards.push({
          id: roomId,
          avatar:
            roomType === RoomType.PRIVATE ? (
              <AvatarComponent name={roomName} />
            ) : roomType === RoomType.GROUP ? (
              <PrivateGroupIcon width={40} height={40} />
            ) : (
              <PublicGroupIcon width={40} height={40} />
            ),
          title: roomName,
          subtitle: isShowCommunityName && roomCommunityId ? titleCaseSchoolNameForUI(roomCommunityId) : undefined,
          body: 'Cursus id vitae quam vulputate tempus ut. Sit proin arcu nisl',
          footer: <div className='text-[#797979] text-xs font-regular ml-auto'>{displayDate}</div>,
        })
      }
    })
    setFilteredRooms(cards.sort((a, b) => a.title.localeCompare(b.title)))
  }, [isShowCommunityName, rooms, searchKeyword, selectedFilter])

  // Filtered Rooms
  return (
    <>
      {/* **************** Body **************** */}
      <div className='flex flex-col flex-1 self-stretch px-5 gap-2 mb-[120px]'>
        {/***************** Search Bar *****************/}
        <InputMobileComponent
          type='text'
          placeholder='Search your groups...'
          onChange={(v: string) => setSearchKeyword(v.trimEnd())}
          rightElement={<SearchIcon />}
        />

        {/***************** Room Type Selector *****************/}
        {filters.length > 2 && (
          <div className='flex flex-wrap my-2 gap-3'>
            {filters.map((v: string, i: number) => (
              <span
                key={i}
                onClick={() => setSelectedFilter(v)}
                className={`text-xs rounded-xl px-2 py-1 cursor-pointer ${
                  selectedFilter === v
                    ? 'bg-custom-yellow text-gray-800'
                    : 'bg-light-yellow border-custom-yellow border text-gray-800'
                }`}
              >
                {v}
              </span>
            ))}
          </div>
        )}

        {/* **************** Room List **************** */}
        {filteredRooms.map((room: CardComponentProps, index: number) => (
          <Link to={`/messaging?roomId=${room.id}`} key={index}>
            <CardComponent
              id={room.id}
              avatar={room.avatar}
              title={room.title}
              subtitle={room.subtitle}
              body={room.body}
              footer={room.footer}
            />
          </Link>
        ))}
      </div>
      {/* **************** Footer **************** */}
      <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-3'>
        <div className='border-b border-gray-200'></div>
        <div className='pt-4 pb-7 px-5'>
          <div className='flex justify-around gap-3'>
            <ButtonMobileComponent
              icon={<CreateGroupIcon />}
              href={`/messaging?action=createRoom`}
              text={'Create group'}
              // className='w-full px-4 py-1.5 bg-custom-yellow text-headings rounded-lg flex items-center justify-center'
            />
            <ButtonMobileComponent
              icon={<JoinGroupIcon />}
              href={`/messaging?action=joinRoom`}
              text={'Join group'}
              // className='w-full px-4 py-1.5 bg-custom-yellow text-headings rounded-lg flex items-center justify-center'
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RoomsComponent
