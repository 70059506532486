import { useMediaQuery, useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import AvatarComponent from 'components/avatar'
import DialogComponent from 'components/dialog'
import { ArrowFilledIcon, InfoIcon, NotifyIcon, PhoneIcon, ScheduleGroupIcon } from 'components/icons'
import WizardComponent from 'components/wizard'
import { FC, useState } from 'react'
import ActivitiesIcon from '../../images/activities_icon.svg'
import CommentIcon from '../../images/comments_icon.svg'
import CopyIcon from '../../images/copy_icon.svg'
import ScheduleIcon from '../../images/schedule.svg'
import TimeIcon from '../../images/time_icon.png'
import UserIcon from '../../images/user_icon.svg'
import LineIcon from '../../images/vertical_bar_icon.png'
import './index.css'

interface DialogUserComponentProps {
  userName: string
  userRelationship: string
  contact: string
  distance: string
  scheduleName?: string
  seatsAvailable?: number
  time?: string
  activities?: string[]
  comments?: string
  onClick: () => Promise<void>
  data_testid?: string
}

const DialogUserComponent = (props: DialogUserComponentProps): JSX.Element => {
  // Use the theme and media query to determine if the dialog should be full-screen
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // for mobile screens

  const {
    data_testid,
    userName,
    userRelationship,
    contact,
    distance,
    scheduleName,
    seatsAvailable,
    time,
    activities,
    comments,
    onClick,
  } = props ?? {}
  const [show, setShow] = useState(false)
  const [tooltipText, setTooltipText] = useState('Copy to clipboard')
  const activityList = Array.isArray(activities) ? activities : []
  const isSeatsAvailable: boolean = seatsAvailable != null && seatsAvailable > 0

  // handlers
  const handleCopy = () => {
    navigator.clipboard.writeText(contact).then(
      () => {
        setTooltipText('Copied!')
        setTimeout(() => {
          setTooltipText('Copy to clipboard')
        }, 3000) // Reset the tooltip after 2 seconds
      },
      () => {
        setTooltipText('Failed to copy')
        setTimeout(() => {
          setTooltipText('Copy to clipboard')
        }, 3000) // Reset the tooltip after 2 seconds
      },
    )
  }
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // For mobile elements we render a custom page that uses the whole screen
  const mobileBody: JSX.Element = (
    <>
      <div>
        <div className='flex items-center gap-2 font-medium pb-3'>
          <img src={UserIcon} alt='' /> Basic Info
        </div>
      </div>
      <div className='flex items-center gap-5 flex-1'>
        <div className='flex items-center gap-3 flex-1'>
          <div className='flex w-10 h-10 p-1.5 justify-center items-center rounded-full bg-[#F7F7F8]'>
            <AvatarComponent name={userName}></AvatarComponent>
          </div>
          <div className='flex flex-col'>
            <div className='flex items-center gap-1'>
              <span className='text-[#474747] text-sm font-medium'>{userName}</span>
              <span className='w-[1px] bg-gray-300 h-[20px]' />
              <span className='text-[#797979] text-xs font-medium'>{userRelationship}</span>
            </div>
            <span className='text-[#FF7900] text-sm font-normal'>{distance} away</span>
          </div>
        </div>
        {isSeatsAvailable && (
          <div className='flex items-center '>
            <div className='text-xs inline-block bg-custom-yellow font-normal text-headings px-2 py-1 rounded-2xl'>
              {seatsAvailable} seats available
            </div>
          </div>
        )}
      </div>
      <div className='flex items-center gap-2 mt-4 '>
        {time && (
          <div className='flex items-center gap-1'>
            <img src={TimeIcon} alt='' />
            <span className='text-headings text-sm font-normal'>{time}</span>
          </div>
        )}
        {time && scheduleName && <img src={LineIcon} alt='' />}
        {scheduleName && (
          <>
            <ScheduleGroupIcon />
            <span className='text-headings text-sm font-normal'>{scheduleName}</span>
          </>
        )}
      </div>
      {comments && (
        <div className='pt-8'>
          <div className='flex items-center gap-2'>
            <img src={CommentIcon} alt='' /> Comments
          </div>
          <div className='text-sm pt-2 text-disable'>{comments}</div>
        </div>
      )}
      {activities && (
        <div className='pt-8'>
          <div className='flex items-center gap-2'>
            <img src={ActivitiesIcon} alt='' /> Activities
          </div>
          <div className='text-sm pt-2 text-disable' data-testid='row-testid'>
            <div className={`flex flex-wrap items-center gap-1 text-sub-headings font-normal text-sm`}>
              {activityList.map((activity: any, index: number) => (
                <div key={index} className='flex items-center gap-1'>
                  <div>{activity}</div>
                  {index < activityList.length - 1 && (
                    <div style={{ background: '#666', width: '5px', height: '5px', borderRadius: '50%' }}></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {false && (
        <div className='pt-8'>
          <div className='flex items-center gap-2'>
            <img src={ScheduleIcon} alt='' /> Schedule
          </div>
          <div className='text-sm text-disable p-4 bg-gray-500 flex gap-2.5 mt-3 rounded-xl'>
            <InfoIcon />
            <div className='text-headings font-medium'>Default school pick up time is 2:30 PM</div>
          </div>
        </div>
      )}
    </>
  )
  const mobileAction: JSX.Element = (
    <>
      <div className='flex items-center gap-2' style={{ textAlign: 'left' }}>
        <PhoneIcon />
        <span className='font-medium text-md text-headings'>Contact Info</span>
      </div>
      <div className='flex gap-3'>
        <div className='flex flex-grow mt-2 items-center gap-2 justify-center text-gray-400 bg-light-yellow border border-custom-yellow py-2 rounded-lg'>
          <Tooltip title={tooltipText} arrow>
            <img src={CopyIcon} alt='' onClick={handleCopy} />
          </Tooltip>
          {contact}
        </div>
        {onClick && (
          <div
            className='flex mt-2 items-center gap-2 justify-center text-gray-400 bg-light-yellow border border-custom-yellow py-2 px-3 rounded-lg cursor-pointer'
            onClick={onClick}
          >
            <NotifyIcon />
          </div>
        )}
      </div>
    </>
  )
  const MobileUserElement: FC = () => (
    <DialogComponent title={userName} body={mobileBody} action={mobileAction} onCancel={handleClose} />
  )
  // For larger screens we reuse the wizard component
  const body: JSX.Element = (
    <WizardComponent title={userName} onBack={handleClose}>
      <div className='flex items-center gap-5 flex-1'>
        <div className='flex items-center gap-3 flex-1 justify-between'>
          <div className='flex'>
            <div className='flex w-10 h-10 p-1.5 justify-center items-center rounded-full bg-[#F7F7F8] mr-3'>
              <AvatarComponent name={userName}></AvatarComponent>
            </div>
            <div className='flex flex-col'>
              <div className='flex items-center gap-1'>
                <span className='text-[#474747] text-sm font-medium'>{userName}</span>
                <span className='w-[1px] bg-gray-300 h-[20px]' />
                <span className='text-[#797979] text-xs font-medium'>{userRelationship}</span>
              </div>
              <span className='text-[#FF7900] text-sm font-normal'>{distance} away</span>
            </div>
          </div>
          <div className='flex gap-3'>
            {onClick && (
              <div
                className='flex mt-2 items-center gap-2 justify-center text-gray-400 bg-btn-grey border-custom-yellow py-2 px-3 rounded-lg cursor-pointer'
                onClick={onClick}
              >
                <NotifyIcon />
                <span className=''>Notify</span>
              </div>
            )}
            <div className='flex  mt-2 items-center gap-2 justify-center text-headings bg-light-yellow border border-custom-yellow rounded-lg py-2 px-2.5'>
              Call :<span className='font-semibold'>{contact}</span>{' '}
              <Tooltip title={tooltipText} arrow>
                <img src={CopyIcon} alt='' onClick={handleCopy} />
              </Tooltip>
            </div>
          </div>
        </div>
        {isSeatsAvailable && (
          <div className='flex items-center '>
            <div className='text-xs inline-block bg-custom-yellow font-normal text-headings px-2 py-1 rounded-2xl'>
              {seatsAvailable} seats available
            </div>
          </div>
        )}
      </div>
      <div className='flex items-center gap-2 mt-2 '>
        {time && (
          <div className='flex items-center gap-1'>
            <img src={TimeIcon} alt='' />
            <span className='text-headings text-sm font-normal'>{time}</span>
          </div>
        )}
        {time && scheduleName && <img src={LineIcon} alt='' />}
        {scheduleName && (
          <>
            <ScheduleGroupIcon />
            <span className='text-headings text-sm font-normal'>{scheduleName}</span>
          </>
        )}
      </div>
      {comments && (
        <div className='pt-4'>
          <div className='flex items-center gap-2'>
            <img src={CommentIcon} alt='' /> Comments
          </div>
          <div className='text-sm pt-2 text-disable'>{comments}</div>
        </div>
      )}
      {activities && (
        <div className='pt-4'>
          <div className='flex items-center gap-2'>
            <img src={ActivitiesIcon} alt='' /> Activities
          </div>
          <div className='text-sm pt-2 text-disable' data-testid='row-testid'>
            <div className={`flex flex-wrap items-center gap-1 text-sub-headings font-normal text-sm`}>
              {activityList.map((activity: any, index: number) => (
                <div key={index} className='flex items-center gap-1'>
                  <div>{activity}</div>
                  {index < activityList.length - 1 && (
                    <div style={{ background: '#666', width: '5px', height: '5px', borderRadius: '50%' }}></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {false && (
        <div className='pt-8'>
          <div className='flex items-center gap-2'>
            <img src={ScheduleIcon} alt='' /> Schedule
          </div>
          <div className='text-sm text-disable p-4 bg-gray-500 flex gap-2.5 mt-3 rounded-xl'>
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
              <path
                d='M9.1665 5.83268H10.8332V7.49935H9.1665V5.83268ZM9.1665 9.16602H10.8332V14.166H9.1665V9.16602ZM9.99984 1.66602C5.39984 1.66602 1.6665 5.39935 1.6665 9.99935C1.6665 14.5993 5.39984 18.3327 9.99984 18.3327C14.5998 18.3327 18.3332 14.5993 18.3332 9.99935C18.3332 5.39935 14.5998 1.66602 9.99984 1.66602ZM9.99984 16.666C6.32484 16.666 3.33317 13.6743 3.33317 9.99935C3.33317 6.32435 6.32484 3.33268 9.99984 3.33268C13.6748 3.33268 16.6665 6.32435 16.6665 9.99935C16.6665 13.6743 13.6748 16.666 9.99984 16.666Z'
                fill='#666666'
              />
            </svg>
            <div className='text-headings font-medium'>Default school pick up time is 2:30 PM</div>
          </div>
        </div>
      )}
    </WizardComponent>
  )
  const UserElement: FC = () => <DialogComponent body={body} backgroundColor={'#F9F9F9'} />

  return (
    <>
      <button
        className='flex w-10 h-10 justify-center items-center rounded-lg bg-[#F0E977]'
        aria-label='More options'
        onClick={handleShow}
        data-testid={data_testid}
      >
        <ArrowFilledIcon />
      </button>
      {show && isMobile && <MobileUserElement />}
      {show && !isMobile && <UserElement />}
    </>
  )
}

export default DialogUserComponent
