import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { DataGrid } from '@mui/x-data-grid'
import { Fragment, useState } from 'react'
import CustomToolbar from './CustomToolbar'

const ExpandableRow = (props: any): JSX.Element => {
  const { row, cols, isOpen } = props
  const [open, setOpen] = useState(isOpen ?? false)

  return (
    <Fragment>
      <TableRow>
        {Object.keys(row)
          .filter((k) => cols.includes(k))
          .map((k: any, i: number) => (
            <TableCell key={i}>
              <>
                {/* Row is only expandable if contents field is defined */}
                {i === 0 && row['contents'] && (
                  <IconButton size='small' onClick={() => setOpen(!open)} data-testid='icon-button'>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                )}
                {row[k]}
              </>
            </TableCell>
          ))}
      </TableRow>
      <TableRow data-testid='row-testid'>
        <TableCell colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ mx: 1 }}>{row['contents']}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const TableComponent = (props: any): JSX.Element => {
  const {
    isAdmin,
    header,
    body,
    isExpandableRow,
    selectedIds,
    onSelectionChange,
    scheduleGroupNames,
    onAddSelected,
    onUploadSelected,
    onDeleteSelected,
    onSendSelected,
    csvOptions,
    printOptions,
  } = props

  if (isExpandableRow) {
    const headerNames = header.reduce((a: any, v: any) => [...a, v.headerName], [])
    const headerFields = header.reduce((a: any, v: any) => [...a, v.field], [])
    return (
      <TableContainer component={Paper}>
        <Table size='small' padding='none'>
          <TableHead>
            <TableRow>
              {headerNames.map((v: string, i: number) => (
                <TableCell key={i}>{v}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {body.map((v: any, i: number) => (
              <ExpandableRow key={i} row={v} cols={headerFields}></ExpandableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  } else {
    const visibleFields = header.reduce((a: any, v: any) => ({ ...a, [v.field]: v?.visible ? true : false }), {})
    return (
      <DataGrid
        autoHeight
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: isAdmin,
            scheduleGroupNames,
            isRowsSelected: selectedIds?.length ? true : false,
            onAddSelected,
            onUploadSelected,
            onDeleteSelected,
            onSendSelected,
            csvOptions,
            printOptions,
          },
        }}
        disableColumnSelector={!isAdmin}
        disableColumnFilter={!isAdmin}
        disableDensitySelector={!isAdmin}
        checkboxSelection={true}
        density='standard'
        rows={body}
        columns={header}
        pagination={true}
        // rows selection event
        rowSelectionModel={selectedIds}
        onRowSelectionModelChange={onSelectionChange}
        initialState={{ columns: { columnVisibilityModel: visibleFields } }}
        data-testid='users-row-selection-checkbox'
      />
    )
  }
}

export default TableComponent
