import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { visuallyHidden } from '@mui/utils' // For hiding text visually
import BackComponent from 'components/back'
import './index.css'

interface DialogComponentProps {
  title?: string
  body: JSX.Element
  action?: JSX.Element
  fullScreen?: boolean
  backgroundColor?: string
  onCancel?: () => void
}

const DialogComponent = (props: DialogComponentProps): JSX.Element => {
  const { title, body, action, fullScreen = true, backgroundColor, onCancel } = props ?? {}
  const minWidth: string = '400px'

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      aria-labelledby='dialog-title'
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
          minWidth,
          backgroundColor,
        },
      }}
    >
      {(title || onCancel) && <BackComponent text={title} onClick={onCancel}></BackComponent>}
      <DialogTitle id='dialog-title' sx={visuallyHidden}>
        Hidden Title for Screen Readers
      </DialogTitle>
      <DialogContent>
        <div className='pt-5'>{body}</div>
      </DialogContent>
      {action && (
        <>
          <div className='border-b border-gray-200'></div>
          <div className='pt-5 pb-10 px-5'>{action}</div>
        </>
      )}
    </Dialog>
  )
}

export default DialogComponent
