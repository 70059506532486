import MsgCardComponent from 'components/msg-card'
import RoomMsgTextAreaComponent from 'components/room-msg-textarea'
import ToastComponent from 'components/toast'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MessageOutputProps } from 'types/MessageTypes'
import { RoomUsersOutputProps, UserRoomsOutputProps } from 'types/RoomTypes'
import { createRoomMsg, getRoomMsgs, onCreateMessageSubscription } from 'utils/rooms'
import { MessageProps } from 'utils/types'

interface RoomComponentProps {
  room: UserRoomsOutputProps
  roomUsers: RoomUsersOutputProps[]
  userId: string
}

const RoomComponent: React.FC<RoomComponentProps> = ({ room, roomUsers, userId }) => {
  const { roomId } = room
  const [messages, setMessages] = useState<MessageOutputProps[]>([])
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const chatContainerRef = useRef<HTMLDivElement | null>(null)

  const lookupUserName = roomUsers.reduce<{ [key: string]: string }>((acc, { userId, userName }) => {
    acc[userId] = userName
    return acc
  }, {})

  // Canned suggested messages
  const suggestedMessages = ['Hello!', 'Can we carpool tomorrow?', "I'm on my way", 'Thank you!']

  // Function to get message count based on screen size
  const getMessageCountForScreen = () => {
    const width = window.innerWidth
    if (width <= 600) return 10
    if (width <= 960) return 15
    return 20
  }

  // Scroll to Bottom
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({ top: chatContainerRef.current.scrollHeight, behavior: 'smooth' })
    }
  }

  // Send Message
  const handleSubmitMessage = async (text: string) => {
    setIsLoading(true)
    const { data, msg, error } = await createRoomMsg(roomId, text)
    if (error || msg) setMsg(msg)
    else if (data) {
      setMessages((prevMessages) => [data.data.createMessage, ...prevMessages]) // Prepend the new message at the beginning
    }
    scrollToBottom() // Scroll to bottom after sending a new message
    setIsLoading(false)
  }

  // Initial load - fetch messages and scroll to bottom once
  useEffect(() => {
    const initialLoad = async () => {
      setIsLoading(true)
      const messageCount = getMessageCountForScreen()
      const { data, msg, error } = await getRoomMsgs(roomId, messageCount)
      if (error || msg) setMsg(msg)
      else if (data) {
        setMessages(data.data.getMessages.items) // Set initial messages
        setNextToken(data.data.getMessages.nextToken || null) // Update nextToken for further fetches
      }
      scrollToBottom() // Scroll to bottom on initial load
      setIsLoading(false)
    }
    initialLoad()
  }, [roomId])

  // Subscription - fetch new messages, but do not scroll to bottom
  useEffect(() => {
    let unsubscribe: (() => void) | undefined

    const handleNewMessage = (newMessage: MessageOutputProps) => {
      setMessages((prevMessages) => [newMessage, ...prevMessages]) // Prepend the new message at the beginning
    }
    const setupSubscription = async () => {
      unsubscribe = await onCreateMessageSubscription(roomId, userId, handleNewMessage)
    }

    setupSubscription()

    return () => {
      if (unsubscribe) unsubscribe() // Cleanup WebSocket connection
    }
  }, [roomId, userId])

  // Fetch older messages when scrolling to the top
  const getMessages = useCallback(async (): Promise<void> => {
    if (isLoading || !nextToken) return // Prevent fetch if already loading or no more messages
    setIsLoading(true)
    const messageCount = getMessageCountForScreen()
    const { data, msg, error } = await getRoomMsgs(roomId, messageCount, nextToken)
    if (error || msg) setMsg(msg)
    else if (data) {
      setMessages((prevMessages) => [...prevMessages, ...data.data.getMessages.items]) // Append older messages
      setNextToken(data.data.getMessages.nextToken || null) // Update nextToken
    }
    setIsLoading(false)
  }, [isLoading, nextToken, roomId]) // Include all dependencies used in getMessages

  const handleScroll = useCallback(() => {
    if (chatContainerRef.current && !isLoading) {
      const { scrollTop } = chatContainerRef.current
      const isAtTop = scrollTop <= 5 // Small threshold for detecting top

      // Load more messages if scrolled to (or near) the top
      if (isAtTop) {
        getMessages()
      }
    }
  }, [isLoading, getMessages]) // Only depends on isLoading to prevent multiple fetches

  if (msg)
    return <ToastComponent style={msg.style} heading={msg.heading} text={msg.text} onClose={() => setMsg(undefined)} />

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column-reverse',
        height: '100vh',
        width: '100%',
        backgroundColor: '#f9f9f9',
        paddingTop: '74px',
      }}
    >
      {/* Fixed Footer with Message Input Area */}
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#f9f9f9', // Match the background color
          padding: '10px',
          zIndex: 10, // Ensure footer stays above the message list
        }}
      >
        <RoomMsgTextAreaComponent onSubmit={handleSubmitMessage} suggestedMessages={suggestedMessages} />
      </div>

      <div
        style={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: '10px',
          backgroundColor: '#f9f9f9', // Match the footer background color
          paddingBottom: '100px', // Offset to make space for fixed footer
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
        ref={chatContainerRef}
        onScroll={handleScroll}
      >
        {messages.map(({ pk, msgText, msgSentBy, msgSentAt }, idx) => (
          <MsgCardComponent
            key={idx}
            text={msgText}
            sender={lookupUserName[msgSentBy]}
            sentAt={msgSentAt}
            isMyMsg={msgSentBy === userId}
          />
        ))}
      </div>
    </div>
  )
}

export default RoomComponent
